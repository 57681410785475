.sld-map{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  #canvas_france{
    width: calc(50% - 1rem);
  }
  #le_commercial{
    width: calc(50% - 1rem);
    background-color: #f7f7f7;
    padding: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border: none; 
    span,
    a{
      margin: 0.5rem 1rem;
      font-size: 1.25rem;
      color: #14599E;
    }
  }
}
@include media-breakpoint-down(md) {
  .sld-map{
    flex-direction: column;
    width: 100%;
    margin: 0;
    #canvas_france{
      width: 100%;
      height: 100%;
    }
    #le_commercial{
      height: 100%;
      width: calc(100% - 2rem);
      margin: -5rem auto 2rem;
    }
  }
}
div .pfg-forms .form-group {
    display: flex;
}

@include media-breakpoint-down(md) {
    div .pfg-forms .form-group>label {
        text-align: center;
    }
}

div .pfg-forms .form-group label.input {
        display: inline-block;
        margin-right: 20px;
}
div .pfg-forms .form-group div span {
    @include media-breakpoint-down(sm) {
        text-align: center;
        display: block;
    }
}
.pfg-forms {
    width: 60%;
    @include media-breakpoint-down(md) {
        width: 90%;
    }
    margin: auto;
    background: #ffffff;
    padding: 0.9375rem;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
}
.pfg-forms h1 {
    text-align: center;
    padding-bottom: 10px;  
}
div .pfg-forms .form-group {
    input:hover:not([type="radio"]), textarea:hover {
        -moz-box-shadow: inset 0 0 2px $color_blue;
        box-shadow: inset 0 0 2px $color_blue
    /*border: 1px solid $color_blue;*/
}
}
@include media-breakpoint-down(lg) {
    div .product-list .grid.columns-4 .product-miniature {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
}
@include media-breakpoint-down(md) {
    div .product-list .grid.columns-4 .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.product-list .grid {
    .product-miniature .product-price-and-shipping {
        padding-bottom: 6px;
    }
     @include media-breakpoint-up(md) {
        .second-block .second-block-wrapper .grid-buy-button {
            margin-left: 10px;
        }
    }
}
.grid .product-miniature .manufacturer.list {
    display: none;
}

.list .product-miniature .manufacturer.grid {
    display: none;
}
.product-list .list .second-block-wrapper .product-price-and-shipping {
    justify-content: flex-start;
    width: 100%;
}
.block-newsletter .form-group .offset-md-3 {
    margin-left: 0;
}
.custom-checkbox {
    display: inline-flex;
    align-self: start;
    margin-top: 3px;
}
div .pfg-forms .form-group div span {
    text-align: left;
}
.paginate_button.next, .paginate_button.previous {
    &.disabled {
        opacity: 0.5;
    }
}
div .material-icons {
    color: inherit;
}
div .savedcartsform {
    display: flex;
}
.savedcartsform input.cartsubmit {
//    @extend .btn;
    font-size: inherit;
    font-weight: normal;
    background: $color_orange;
    border-radius: 0 3px 3px 0;
    text-transform: uppercase;
    color: $color_white;
    &:hover {
        background: darken($color_orange, 10%);
    }
}
.footer_links .footersavedcarts,
#block-history a.footersavedcarts {
    @extend .btn;
    @extend .btn-primary;
    padding: 7px 3%;
}
.savedcartsform input.cartname {
    border-radius: 3px 0 0 3px;
    margin-right: -5px;
}
div .savedcartsform input {
    height: auto;
}
div td.savedcarticon {
    font-size: inherit !important;
}
.ui-autocomplete-input {
    color: #555;
}
.shopping-list ul.open-shopping-list {
    display: block;
    flex-direction: column;
    z-index: 99999;
}
#shopping-list img {
  max-width: 100px;
}
#shopping-list .operations-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#quickorderform {
    .label-combination, .product-combinations, .label-stock, .product-stock {
        display: none;
    }
}
#shipping-address-infos-wrapper {
  padding: 50px;
  margin-bottom: 30px;
  border: 2px solid #14599e;
  border-radius: 4px;
  background: #fff;
  #shipping-address-infos {
    width: max-content;
    padding: 25px;
    margin: 0 auto;
    border: 2px solid #ec641d;
    border-radius: 4px;
    #shipping-address-infos-alias {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
    }
    #shipping-address-infos-address,
    #shipping-address-infos-city {
      text-transform: lowercase;
    }
  }
}