.category-description {
  .small-description {
    overflow: hidden;
    position: relative;
  }
  .description-expand {
    margin-top: 15px;
    display: none;
  }
  .small-description.see-more {
    max-height: 100px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 35px;
      background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(247, 247, 247, 0)), color-stop(40%, rgba(247, 247, 247, 0.8)), color-stop(90%, #f7f7f7));
      background-image: -webkit-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: -moz-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: -o-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
    }
    + .description-expand {
      display: block;
    }
  }
}
.category-cover {
}

.subcategories-wrapper {
}
.subcategory-miniature {
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .subcategory-description {
    display: none;
  }
  .subcategory-name {
    margin-top: 10px;
    font-size: 1em;
    text-align: center;
  }
  .subcategory-image {
    img {
      border-radius: 3px;
    }
  }
  &.no-image {
    .subcategory-name {
      margin-top: auto;
    }
  }
}

.products-selection {
  padding: 0.5rem 0;
  .total-products {
    p {
      margin-bottom: 0;
      color: $color_green;
      @include media-breakpoint-down(md) {
        margin-top: 10px;
      }
    }
  }
  .products-sort-order {
    .sort-label {
      margin-right: 10px;
    }
    .sort-select {
      width: 100%;
      @include media-breakpoint-up(md) {
        max-width: $input-max-width;
      }
    }
    .select-title {
      cursor: pointer;
      min-height: $base-height;
      white-space: nowrap;
      text-align: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-menu {
      min-width: 100%;
      width: auto;
    }
    .product-display {
      margin-left: auto;
      .display-label {
        margin-right: 10px;
      }
      .display-select {
        font-size: ($line-height-base*$font-size-root) + 2px;
        margin: 0;
        background: $light-background;
        padding: $base-padding-border2px 5px;
        border: 1px solid $border-color;
        border-radius: 5px;
        a {
          margin: 0 2px;
            color:  $color_dark_gray;
          .material-icons, .fa {
            margin-top: 0;
          }
            &:hover {
                color : $link-color;
            }
        }
        .selected {
          color: $color_dark_blue;
        }
        .material-icons {
          display: block;
        }
      }
    }
  }
  .filter-button {
    #search_filter_toggler {
      width: 100%;
    }
  }
}

.pagination-wrapper {
}
.pagination {
  width: 100%;
  margin: 0;
  display: block;
  .page-total {
    font-weight: 700;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }
  .row {
    align-items: center;
  }
  .page-list {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    li {
    }
    a {
      padding: 1px 10px;
      font-weight: 600;
      text-transform: uppercase;
      display: block;
      &:hover {
        background-color: $color_gray;
      }
      .material-icons {
        font-size: 14px;
        margin-top: -2px;
      }
    }
    .current a {
      background-color: $link-color;
      color: $light-text-color;
    }
    .previous {
      padding-left: 0px;
      font-size: 90%;
    }
    .next {
      padding-right: 0px;
      font-size: 90%;
    }
  }
}

.left-categories {
  .column-title {
    margin-bottom: 12px;
  }
  
  .arrows {
    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;
      &:hover {
        color: $primary;
      }
    }
    .arrow-down {
      display: none;
    }
    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }
      .arrow-down {
        display: inline-block;
      }
    }
  }
}
.category-tree {
  ul {
    margin-bottom: 0;
    li {
      position: relative;
    }
  }
  .collapse-icons {
    position: absolute;
    right: -5px;
    top: $base-padding;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
    text-align: center;
    width: ($line-height-base * $font-size-root);
    height: ($line-height-base * $font-size-root);
    line-height: (($line-height-base * $font-size-root) - 1);
    border: none;
    user-select: none;
    &[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: inline-block;
      }
    }
    .add,
    .remove {
      font-weight: 700;
      margin-top: -1px;
    }
    .remove {
      display: none;
    }
  }
  
  > ul > li {
    padding: $base-padding 0;
    > a {
      font-size: 1.1em;
      margin-right: 8px;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border: 1px solid $border-color;
        border-radius: 50%;
        display: inline-block;
      }
      &:hover:before {
        border-color: $link-hover-color;
        background-color: $link-hover-color;
      }
      img {
        max-height: 33px;
        margin: -8px 3px -6px -21px;
      }
      @include media-breakpoint-only(md) {
        .left-categories & {
          font-size: 1em;
        }
      }
    }
  }
  .category-sub-menu {
    border-left: 1px solid $border-color;
    border-right: 0px solid $border-color;
    padding-top: 3px;
    margin-left: 15px;
    .category-sub-menu {
      margin-left: 0;
    }
    > ul > li {
      padding: 3px 0 3px 10px;
      .collapse-icons {
        right: 0;
        top: 3px;
      }
    }
  }
  a.current {
    font-weight: 700;
  }
}

.manufacturer-list {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .brand {
    margin-bottom: 20px;
    .brand-container {
    }
    .brand-img {
      text-align: center;
      img {
        border: none;
        padding: 0;
      }
    }
    .brand-name {
      font-size: 0.875rem;
      font-family: $text-font;
      text-align: center;
      margin: 15px -15px;
      padding: 8px 0;
      background: $color_gray;
    }
    .brand-products {
      display: flex;
      justify-content: space-around;
    }
    .brand-desc {
      width: 100%;
      display: none;
    }
  }
}

/*** Responsive part ***/

