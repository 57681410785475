.block-reassurance {
    .block-reassurance-item {
        padding: $box-padding;
        display: flex;
        align-items: center;
        padding: 2%;
        img {
          width: 35px;
          height: auto;
            margin: auto !important;
            
        }
        span {
            
        }
        @include media-breakpoint-up(lg) {
          img {
                width: 20%;
                padding-right: 3%;
                margin-right: 10px;
            }
            span {
                width: 72%;
                text-align: left;
                line-height: 120%;
            }
        }
  }
  li + li {
    border-top: 1px dashed $border-color;
      padding-right: 15px;
  }
    @include media-breakpoint-up(md) {
        ul li {
            width: calc(100%/3);
            float: left;
            padding-right: 0;
            border-top: none;

        }
          .block-reassurance-item {
              flex-wrap: wrap;
              justify-content: flex-start;
                text-align: center;
              padding: 5px;
              img {
                  margin-right: 0;
              }
        }
      }
    @include media-breakpoint-down(md) {
        
        ul li {
            text-align: center;
        }
        .block-reassurance-item {
            display: inline-flex;
            img {
                  margin-right: 10px !important;
              }
        }
    }
    @include media-breakpoint-only(md) {
        .block-reassurance-item {
            img {
                  margin-right: auto !important;
              }
        }
    }


}
